import React from 'react';
import homeStyles from './home-header.module.scss';
import ConfitureDeFraise from './images/confiture-de-fraise';
import EtiquetteScolaire from './images/etiquette-scolaire';

const Home = () => (
  <>
    <section className="section">
      <div className={`container ${homeStyles.presentation}`}>
        <div className="columns">
          <div className="column is-6">
            <h2 className="title is-4 is-spaced m-t-md">
              Étiquettes Personnalisées
            </h2>
            <h4 className={`subtitle ${homeStyles.subtitle}`}>
              Créez l&apos;étiquette de votre choix en 5 petites étapes.
              Choisissez la forme, l&apos;illustration, la couleur, le texte et le tour est joué !
            </h4>
          </div>
          <div className={`column is-6 is-centered ${homeStyles.label}`}>
            <ConfitureDeFraise />
          </div>
        </div>

        <div className="columns m-t-md is-hidden-mobile">
          <div className={`column is-6 is-centered ${homeStyles.label}`}>
            <EtiquetteScolaire />
          </div>
          <div className="column is-6">
            <h3 className="title is-4 is-spaced m-t-md">
              Facile et gratuit
            </h3>
            <h4 className={`subtitle ${homeStyles.subtitle}`}>
              Une fois votre étiquette créée vous pourrez l&apos;imprimer
              gratuitement depuis chez vous.
            </h4>
          </div>
        </div>

        <div className="columns m-t-md is-hidden-tablet">
          <div className="column is-6">
            <h3 className="title is-4 is-spaced m-t-md">
              Facile et gratuit
            </h3>
            <h4 className={`subtitle ${homeStyles.subtitle}`}>
              Une fois votre étiquette créée vous pourrez l&apos;imprimer
              gratuitement depuis chez vous.
            </h4>
          </div>
          <div className={`column is-6 is-centered ${homeStyles.label}`}>
            <EtiquetteScolaire />
          </div>
        </div>
      </div>
    </section>
  </>
);

Home.propTypes = {
};

Home.defaultProps = {
};

export default Home;
