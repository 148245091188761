import { graphql } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import CategoryCards from '../components/category-cards';
import HomeHeader from '../components/home-header';
import Layout from '../components/layout';
import News from '../components/news';
import SEO from '../components/seo';

const IndexPage = ({ intl, data }) => {
  const { allMarkdownRemark: { edges } } = data;
  const news = [];
  edges.forEach((n, index) => news.push(
    <News data={n.node} index={index} key={n.node.id} />,
  ));

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'index.title' })} />
      <HomeHeader />
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            {/* <p className="title is-3">
              <FormattedMessage id="index.last-news" />
            </p> */}
            {news}
          </div>
          <div className="container m-t-xl m-b-lg">
            <h2 className="title is-4">
              <FormattedMessage id="index.choose-label" />
            </h2>
            <div className="columns">
              <div className="column is-6-mobile is-offset-3-mobile is-12-desktop is-8-widescreen is-offset-2-widescreen">
                <CategoryCards />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section">
      <div className={`container ${homeStyles.presentation}`}>
        <div className="columns">
          <div className="column">
            <h1 className="title is-1">
              <FormattedMessage id="index.title" />
            </h1>
          </div>
          <div className="column">
            <Link to="/create/shape">Go Label Maker</Link>
          </div>
        </div>
      </div>
    </section> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 1,
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { fileAbsolutePath: {regex: "/news/.*\\.md$/"} }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(IndexPage);
